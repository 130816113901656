<template>
  <div class="auth-wrapper auth-v2" :style="{ backgroundImage: `url('${$apiUrl}/images/common/login-bg.webp')` }">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col cols="12" md="5" lg="4" xl="3"
        class="d-flex m-auto align-items-center auth-bg justify-content-center align-content-center">
        <div>
          <div class="w-100 d-flex justify-content-center mt-1">
            <img class="login-img-logo" :src="require('@/assets/images/app-images/mom_r_wide.svg')" />
          </div>
          <!-- <locale class="locale-wrapper" style="list-style: none" /> -->
          <div
            class="w-100 mt-1 d-flex justify-content-center align-content-center align-items-center align-self-center">
            <b-form-group>
              <b-form-radio-group id="btn-radios-login-type" v-model="currentLoginFor" :options="loginTypeOpts"
                button-variant="outline-light" name="radio-btn-outline" buttons />
            </b-form-group>
            <!-- <div class="d-flex justify-content-end align-items-center">
              <locale class="locale-wrapper" style="list-style: none" />
            </div> -->
          </div>
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form px-2" @submit.prevent>
              <b-form-group :label="$t('SIGN IN VIA')" class="text-center" label-class="text-light fs-1p2rem">
                <b-form-radio-group v-model="loginType"
                  class="demo-inline-spacing mb-1 justify-content-center no-margin-images">
                  <b-form-radio value="email">
                    <img title="Email" class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-mail-94.png')" />
                  </b-form-radio>
                  <b-form-radio value="mobile">
                    <img title="Mobile" class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-smartphone-94.png')" />
                  </b-form-radio>
                  <b-form-radio value="whatsapp">
                    <img title="Whatsapp" class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-whatsapp-94.png')" />
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <!-- email -->
              <b-form-group v-if="loginType == 'email'" :label="$t('Email')" label-for="login-email"
                label-class="text-light fs-1rem">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="loginType == 'mobile' || loginType == 'whatsapp'" :label="$t('Phone Number')"
                label-class="text-light fs-1rem">
                <validation-provider #default="{ errors }" name="Phone" rules="required">
                  <VuePhoneNumberInput v-model="userPhone" :state="errors.length > 0 ? false : null"
                    placeholder="+91 0000000000" @update="onUpdate" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password" class="text-light fs-1rem">{{ $t('Password') }}</label>
                  <b-link v-if="$route.meta.sharedType !== 'admin'" :to="{
                    name: $route.meta.sharedType == 'provider' ? 'apps-provider-forget-password' : $route.meta.sharedType == 'shop' ? 'apps-shop-forget-password' : 'apps-forget-password',
                    query: $route.meta.sharedType == 'provider' && loginAs == 'agent' ? { agent: 1 } : {},
                  }">
                    <span class="fs-1rem">{{ $t('Forgot Password?') }}</span>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <button type="submit"
                class="w-100 custom-design custom-design-black px-md-3 font-weight-bold tra-black-bg"
                @click="validationForm">{{ $t('Sign in as') }} {{ $t(userTypeName) }}</button>
            </b-form>
          </validation-observer>
          <b-card-text
            v-if="$route.meta.sharedType === 'user' || $route.meta.sharedType === 'provider' || $route.meta.sharedType === 'shop'"
            class="text-center mt-2">
            <span>{{ $t('New on our platform?') }} </span>
            <b-link
              :to="{ name: $route.meta.sharedType === 'provider' ? 'apps-provider-registration' : $route.meta.sharedType === 'shop' ? 'apps-shop-registration' : 'apps-registration' }">
              <span>&nbsp;{{ $t('Create an account') }}</span>
            </b-link>
          </b-card-text>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn mb-1 d-flex justify-content-center">
            <!-- <facebook-login
              app-id="518236876494478"
              class="face-book-login-button px-0"
              :login-label="''"
              @login="onLogin"
              @onLogout="onLogout"
              @get-initial-status="getUserData"
              @sdk-loaded="sdkLoaded"
              :logoutLabel="' '"
            /> -->
            <div>
              <img :src="`${$apiUrl}/images/icons/facebook.svg`" width="44" class="cp" @click="facebookLogin" />
            </div>
            <div id="g_id_onload" class="ml-1"></div>
            <div class="g_id_signin"></div>
          </div>
          <b-card-text class="text-left mb-1">
            <span>{{ $t('By continuing, you agree to our') }} </span>
            <b-link :to="{ path: '/terms-and-condition' }">
              <span>&nbsp;{{ $t('Terms of Use') }}</span>
            </b-link>
            {{ $t('and') }}
            <b-link :to="{ path: '/privacy-policy' }">
              <span>&nbsp;{{ $t('Privacy Policy') }}</span>
            </b-link>
          </b-card-text>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BFormRadioGroup, BFormRadio, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuePhoneNumberInput from 'vue-phone-number-input'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { fbLogin } from './init-facebook-sdk'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import jwt_decode from 'jwt-decode'
import messaging from '../../firebase.js' // Adjust the path
import { getToken } from 'firebase/messaging'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'
export default {
  components: {
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      isConnected: false,
      FB: null,
      loginType: 'email',
      phoneNumberPayload: null,
      userEmail: '',
      passwordFieldType: 'password',
      userPhone: '',
      loginAs: 'provider',
      // eslint-disable-next-line import/no-unresolved
      sideImg: require('@/assets/images/app-images/login-animation.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      googlePrompt: null,
      currentLoginFor: '',
      loginTypeOpts: [
        { text: 'User', value: 'user' },
        { text: 'Shop', value: 'shop' },
        { text: 'Provider', value: 'provider' },
        { text: 'Agent', value: 'agent' },
      ],
    }
  },
  computed: {
    userTypeName() {
      if (this.$route.meta.sharedType === 'provider') {
        if (this.loginAs === 'provider') return 'Provider'
        return 'Agent'
      }
      if (this.$route.meta.sharedType === 'admin') return 'Admin'
      if (this.$route.meta.sharedType === 'shop') return 'Shop'
      return 'User'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/app-images/login-animation.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    if (window.FB === undefined) {
      console.log('FB undefined -> provide callback');
      window.fbAsyncInit = function () {
        this.loadFacebookSDK()
      };
    }
    else {
      console.log('FB defined -> call init right away');
      this.loadFacebookSDK()
    }
  },
  mounted() {
    this.currentLoginFor = this.$route.meta.sharedType === 'provider' ? this.loginAs : this.$route.meta.sharedType;
    if (this.$route.meta.sharedType === 'user') {
      localStorage.removeItem('userLoggedIn')
      localStorage.removeItem('userData')
      localStorage.removeItem('userType')
    }
    window.socialLoginParams = payload => {
      this.makeSocialLogin(payload)
    }
    window.google.accounts.id.initialize({
      client_id: this.$superApp.google.clientId,
      callback: res => {
        this.OnGoogleAuthSuccess(res.credential)
      },
    })
    const parent = document.getElementById('g_id_onload')
    window.google.accounts.id.renderButton(parent, {
      theme: 'outline',
      type: 'icon',
      shape: 'circle',
      size: 'large',
    })
    window.google.accounts.id.prompt()
  },
  destroyed() {
    window.socialLoginParams = null
  },
  methods: {
    loadFacebookSDK() {
      window.FB.init({
        appId: '518236876494478',
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
    },
    facebookLogin() {
      FB.login(response => {
        if (response.authResponse) {
          this.handleFBLogin(response.authResponse);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'public_profile,email' });
    },
    handleFBLogin(authResponse) {
      const thisClass = this
      FB.api('/me', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, function (response) {
        console.log(response, 'responseq');
        thisClass.makeSocialLogin({
          social_unique_id: response.id,
          login_by: 'FACEBOOK',
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          picture: response.picture?.data?.url ?? null,
        })
      });
    },
    OnGoogleAuthSuccess(idToken) {
      const payload = jwt_decode(idToken)
      this.makeSocialLogin({
        social_unique_id: payload.sub,
        login_by: 'GOOGLE',
        first_name: payload.given_name,
        last_name: payload.family_name,
        email: payload.email,
        picture: payload.picture,
      })
    },
    OnGoogleAuthFail() {
      showDangerNotification(this, 'Failed to login !!')
    },
    getUserData() {
      if (!this.FB) return
      this.FB.api('/me', 'GET', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, async response => {
        await this.onLogout()
        if (response.error) {
          showDangerNotification(this, 'Failed to login !!')
          return
        } else if (response.email == null || response.email == '' || typeof response.email == typeof undefined) {
          showDangerNotification(this, 'Email address not found on your Facebook account try alternate method for Login (Manual/Google)!!')
          return false
        }
      })
    },
    sdkLoaded(payload) {
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
      if (window.socialLoginParams) {
        window.socialLoginParams({
          social_unique_id: response.id,
          login_by: 'FACEBOOK',
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          picture: response.picture && response.picture.data ? response.picture.data.url : '',
        })
      }
    },
    onLogout() {
      if (!this.FB) return
      this.isConnected = false
      try {
        this.FB.logout()
      } catch { }
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    switchLogin(formData) {
      if (this.$route.meta.sharedType === 'provider') {
        if (this.loginAs === 'provider') {
          return useJwt.providerLogin(formData)
        }
        return useJwt.agentLogin(formData)
      }
      if (this.$route.meta.sharedType === 'shop') {
        return useJwt.shopLogin(formData)
      }
      if (this.$route.meta.sharedType === 'admin') {
        return useJwt.adminLogin(formData)
      }
      return useJwt.login(formData)
    },
    switchSocailLogin(formData) {
      if (this.$route.meta.sharedType === 'provider') {
        return useJwt.providerSocailLogin(formData)
      }
      if (this.$route.meta.sharedType === 'shop') {
        return useJwt.shopSocailLogin(formData)
      }
      return useJwt.loginSocial(formData)
    },
    makeSocialLogin(payload) {
      const formData = new FormData()
      formData.append('social_unique_id', payload.social_unique_id)
      formData.append('login_by', payload.login_by)
      formData.append('first_name', payload.first_name)
      formData.append('last_name', payload.last_name)
      formData.append('filter', 'email')
      formData.append('email', payload.email)
      formData.append('picture', payload.picture)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      window.localStorage.setItem('userSocialInfo', JSON.stringify(payload))
      if(this.loginAs == 'agent'){
        localStorage.setItem('agent_on_login', 1)
      }
      this.switchSocailLogin(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.setLoginInformations(data)
          } else if (data.responseData.status) {
            this.setLoginInformations(data)
          } else {
            showDangerNotification(this, data.message)
            if (data.responseData.validators && data.responseData.validators.length > 0) {
              if (this.$route.meta.sharedType === 'user') {
                this.$router.push({ name: 'apps-registration' })
              }
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const formData = new FormData()
          if (this.loginType === 'email') {
            formData.append('filter', 'email')
            formData.append('email', this.userEmail)
          }
          if (this.loginType === 'mobile' || this.loginType == 'whatsapp') {
            formData.append('filter', 'phone')
            formData.append('country_code', this.phoneNumberPayload.countryCallingCode)
            formData.append('mobile', this.phoneNumberPayload.nationalNumber)
          }
          formData.append('password', this.password)
          formData.append('salt_key', window.SuperApp.getters.saltKey())
          if (this.$route.meta.sharedType === 'admin') {
            formData.append('role', 'Admin')
          }
          showLoader()
          this.switchLogin(formData)
            .then(response => {
              hideLoader()
              const responseLogin = response.data
              if (responseLogin.statusCode === '200') {
                this.setLoginInformations(responseLogin)
              } else {
                showDangerNotification(this, 'Failed to Loginss')
              }
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    setLoginInformations(responseLogin) {
      useJwt.setToken(responseLogin.responseData.access_token)
      useJwt.setRefreshToken(responseLogin.responseData.access_token)
      window.SuperApp.getters.setUserInfo(JSON.stringify(responseLogin.responseData.user))
      window.SuperApp.getters.setUserType(this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user')
      window.SuperApp.getters.setUserAccess(responseLogin.responseData.access_token)
      showSuccessNotification(this, 'Logged in successfully')
      window.SuperApp.getters.setUserLoggedIn(true)
      if (responseLogin.responseData.user.city_id) {
        localStorage.setItem('userSelectedCityId', responseLogin.responseData.user.city_id)
      }
      if (!document.body.classList.contains('modal-open')) {
        window.SuperApp.helper.changeTranslation(this, window.SuperApp.getters.userLang())
        if (this.$route.query.returnUrl) {
          this.$router.push({ path: this.$route.query.returnUrl })
        } else if (window.SuperApp.getters.isUser()) {
          this.requestPermission()
          this.$router.push({ name: 'user-dashboard' })
        } else if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) {
          this.requestPermission()
          this.$router.push({ name: 'provider-requests' })
      } else if (window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent()) {
          localStorage.setItem('agent_on_login', 1)
          this.requestPermission()
          this.$router.push({ name: 'provider-agent-dashboard' })
        } else if (window.SuperApp.getters.isStore()) {
          this.requestPermission()
          this.$router.push({ name: 'shop-dashboard' })
        } else if (window.SuperApp.getters.isAdmin()) {
          this.$router.push({ name: 'admin-cmspages-faq' })
        }
        window.SuperApp.helper.requestPermission()
      } else {
        window.location.reload()
      }
    },
    loginWithFacebook() {
      fbLogin()
    },
    getFireBaseToken() {
      // const vapidKey = "BOBK-MFmY8653vT_8lqpMRFVaiTBz7SF_Z9f0nIKngg5Eja2R2Mse1Uy86XH5nYveWaSGWRyciCiGp4qZD2BLdc"; //MOM
      // const vapidKey = "BMQkIrVfaRiAkdSfHIbPaSvvGFozrNBgxWCwXvn7upGR_VpH8ma8od1_EmpLEkRtHJ-z2389Cgu0Zg9TRZoQQos"; //MY
      // const vapidKey = "BINB0qoLSxm67Y1Gc5v5h6FgvSz0cwb3P-xPC0g0DY4jo1sHrGhjSWQXoPaEoeM2jb-jZtVdc_FcdQ-3KiKlZ9s"; //MOM NEW
      const vapidKey = 'BOg_x200CyDuCF-v-1A26TzvTU_HVUXvyNZZQXuOAIBSfkSnV0zNDu_hjQwMqNcZzJEUZ0xvR2b3BLfkfhWl1FQ' //MOM IT NOW
      getToken(messaging, { vapidKey })
        .then(currentToken => {
          if (currentToken) {
            console.log(currentToken)
            console.log('currentToken ^^^^^^ ')
            axios.post(`${jwtDefaultConfig.apiEndpoint}/firebase/register-device`, {
              device_token: currentToken,
            })
          } else {
            console.log('No registration token available. Request permission to generate one.')
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err)
        })
    },
    requestPermission() {
      Notification.requestPermission().then(permission => {
        console.log(permission, 'permission')
        if (permission === 'granted') {
          this.getFireBaseToken()
        }
      })
    },
  },
  watch: {
    currentLoginFor(nv) {
      console.log(this.$route.meta.sharedType, "this.$route.meta.sharedType");
      console.log(nv, "nv");

      this.loginAs = 'provider'
      if (nv === 'agent') this.loginAs = 'agent';
      if (this.$route.meta.sharedType === 'provider' && (nv == 'provider' || nv == 'agent')) return false;
      if (nv === 'user') {
        this.$router.push({
          name: 'apps-login'
        })
        return;
      } else {
        let newRoute = nv
        if (nv == 'agent') {
          newRoute = 'provider'
        }
        this.$router.push({
          name: `apps-${newRoute}-login`
        })
        return;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.no-margin-images {
  margin-top: 8px !important;

  .custom-control-inline {
    margin-top: 10px !important;
  }
}

.auth-footer-btn {
  button {
    border: 0;
    background-color: transparent !important;
  }
}

.text-light label {
  color: #FFF
}
</style>

<style scoped>
.auth-v2 {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
